@font-face {
  font-family: font;
  src: url("./assets/fonts/DIOGENES.ttf");
}

html {
  font-family: font;
  scroll-behavior: smooth;
}

body {
  background-color: #101417;
  color: #fff2ae;
}

a {
  cursor: pointer;
}

.App {
  overflow: hidden;
}

.btn-primary {
  background-color: #9b3d09;
  padding: 10px 40px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
}

.ut {
  border-radius: 60px 60px 15px 15px;
  border: 3px solid #9b3d09;
}

.rm {
  /* border-radius: 100px 100px 15px 15px; */
}
